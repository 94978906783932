var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex-column justify-left align-center",style:(_vm.display ? 'width: 300px; height: 310px;' : '')},[(_vm.localProperty != null)?_c('div',{class:'silo-container ' + (_vm.display ? 'silo-display-small' : 'silo-create'),style:(_vm.display == false
        ? 'width: 257px; height: 500px;'
        : 'width: 175px; height: 250px;')},[(_vm.displaySize)?_c('div',{staticClass:"silo-display-size"},[_c('h3',[_vm._v(_vm._s(_vm.size)+" m³")])]):_vm._e(),(_vm.type != null)?_c('v-icon',{staticStyle:{"position":"relative","top":"0px","left":"5px"},attrs:{"large":"","color":_vm.type == 0 ? 'primary' : 'accent'}},[_vm._v(_vm._s(_vm.type == 0 ? "mdi-cylinder" : "mdi-cube"))]):_vm._e(),_c('v-text-field',{class:'silo-input ' +
        (_vm.display == false
          ? 'silo-input-top-diameter'
          : 'silo-input-top-diameter-display'),attrs:{"type":"number","outlined":"","dense":"","filled":"","hide-details":"","disabled":_vm.display},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-container',{staticStyle:{"background":"#264e6e","color":"white","font-weight":"bold","width":"100%","height":"100%","border-top-right-radius":"5px","border-bottom-right-radius":"5px"}},[_vm._v("mm")])]},proxy:true}],null,false,1446469676),model:{value:(_vm.topDiameter),callback:function ($$v) {_vm.topDiameter=$$v},expression:"topDiameter"}}),_c('v-text-field',{class:'silo-input ' +
        (_vm.display == false
          ? 'silo-input-top-height'
          : 'silo-input-top-height-display'),attrs:{"type":"number","outlined":"","dense":"","filled":"","hide-details":"","disabled":_vm.display},on:{"blur":_vm.calculateVolume},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-container',{staticStyle:{"background":"#264e6e","color":"white","font-weight":"bold","width":"100%","height":"100%","border-top-right-radius":"5px","border-bottom-right-radius":"5px"}},[_vm._v("mm")])]},proxy:true}],null,false,1446469676),model:{value:(_vm.localProperty.topHeight),callback:function ($$v) {_vm.$set(_vm.localProperty, "topHeight", $$v)},expression:"localProperty.topHeight"}}),_c('v-text-field',{class:'silo-input ' +
        (_vm.display == false
          ? 'silo-input-base-diameter'
          : 'silo-input-base-diameter-display'),attrs:{"type":"number","outlined":"","dense":"","filled":"","hide-details":"","disabled":_vm.display},on:{"blur":_vm.calculateVolume},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-container',{staticStyle:{"background":"#264e6e","color":"white","font-weight":"bold","width":"100%","height":"100%","border-top-right-radius":"5px","border-bottom-right-radius":"5px"}},[_vm._v("mm")])]},proxy:true}],null,false,1446469676),model:{value:(_vm.localProperty.baseDiameter),callback:function ($$v) {_vm.$set(_vm.localProperty, "baseDiameter", $$v)},expression:"localProperty.baseDiameter"}}),_c('v-text-field',{staticClass:"silo-input silo-input-base-height",class:'silo-input ' +
        (_vm.display == false
          ? 'silo-input-base-height'
          : 'silo-input-base-height-display'),attrs:{"type":"number","outlined":"","dense":"","filled":"","hide-details":"","disabled":_vm.display},on:{"blur":_vm.calculateVolume},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-container',{staticStyle:{"background":"#264e6e","color":"white","font-weight":"bold","width":"100%","height":"100%","border-top-right-radius":"5px","border-bottom-right-radius":"5px"}},[_vm._v("mm")])]},proxy:true}],null,false,1446469676),model:{value:(_vm.localProperty.baseHeight),callback:function ($$v) {_vm.$set(_vm.localProperty, "baseHeight", $$v)},expression:"localProperty.baseHeight"}}),_c('v-text-field',{class:'silo-input ' +
        (_vm.display == false
          ? 'silo-input-bottom-diameter'
          : 'silo-input-bottom-diameter-display'),attrs:{"type":"number","outlined":"","dense":"","filled":"","hide-details":"","disabled":_vm.display},on:{"blur":_vm.calculateVolume},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-container',{staticStyle:{"background":"#264e6e","color":"white","font-weight":"bold","width":"100%","height":"100%","border-top-right-radius":"5px","border-bottom-right-radius":"5px"}},[_vm._v("mm")])]},proxy:true}],null,false,1446469676),model:{value:(_vm.localProperty.bottomDiameter),callback:function ($$v) {_vm.$set(_vm.localProperty, "bottomDiameter", $$v)},expression:"localProperty.bottomDiameter"}}),_c('v-text-field',{class:'silo-input ' +
        (_vm.display == false
          ? 'silo-input-bottom-height'
          : 'silo-input-bottom-height-display'),attrs:{"type":"number","outlined":"","dense":"","filled":"","hide-details":"","disabled":_vm.display},on:{"blur":_vm.calculateVolume},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-container',{staticStyle:{"background":"#264e6e","color":"white","font-weight":"bold","width":"100%","height":"100%","border-top-right-radius":"5px","border-bottom-right-radius":"5px"}},[_vm._v("mm")])]},proxy:true}],null,false,1446469676),model:{value:(_vm.localProperty.bottomHeight),callback:function ($$v) {_vm.$set(_vm.localProperty, "bottomHeight", $$v)},expression:"localProperty.bottomHeight"}}),_c('v-text-field',{class:'silo-input-outside ' +
        (_vm.display
          ? 'silo-input-install-height-display'
          : 'silo-input-install-height'),attrs:{"type":"number","outlined":"","dense":"","light":"","hide-details":"","label":_vm.$t('silos.siloProperty.fields.installHeight'),"disabled":_vm.display},on:{"blur":_vm.calculateVolume},model:{value:(_vm.localProperty.installHeight),callback:function ($$v) {_vm.$set(_vm.localProperty, "installHeight", $$v)},expression:"localProperty.installHeight"}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }