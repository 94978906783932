<template>
  <div
    :style="display ? 'width: 300px; height: 310px;' : ''"
    class="d-flex-column justify-left align-center"
  >
    <div
      :class="
        'silo-container ' + (display ? 'silo-display-small' : 'silo-create')
      "
      :style="
        display == false
          ? 'width: 257px; height: 500px;'
          : 'width: 175px; height: 250px;'
      "
      v-if="localProperty != null"
    >
      <div class="silo-display-size" v-if="displaySize">
        <h3>{{ size }} m³</h3>
      </div>

      <v-icon
        v-if="type != null"
        large
        :color="type == 0 ? 'primary' : 'accent'"
        style="position: relative; top: 0px; left: 5px"
        >{{ type == 0 ? "mdi-cylinder" : "mdi-cube" }}</v-icon
      >
      <v-text-field
        type="number"
        outlined
        dense
        :class="
          'silo-input ' +
          (display == false
            ? 'silo-input-top-diameter'
            : 'silo-input-top-diameter-display')
        "
        filled
        hide-details
        v-model="topDiameter"
        :disabled="display"
      >
        <template v-slot:append
          ><v-container
            style="
              background: #264e6e;
              color: white;
              font-weight: bold;
              width: 100%;
              height: 100%;
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            "
            >mm</v-container
          ></template
        >
      </v-text-field>
      <v-text-field
        type="number"
        outlined
        dense
        :class="
          'silo-input ' +
          (display == false
            ? 'silo-input-top-height'
            : 'silo-input-top-height-display')
        "
        filled
        hide-details
        v-model="localProperty.topHeight"
        :disabled="display"
        @blur="calculateVolume"
      >
        <template v-slot:append
          ><v-container
            style="
              background: #264e6e;
              color: white;
              font-weight: bold;
              width: 100%;
              height: 100%;
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            "
            >mm</v-container
          ></template
        >
      </v-text-field>
      <v-text-field
        type="number"
        outlined
        dense
        :class="
          'silo-input ' +
          (display == false
            ? 'silo-input-base-diameter'
            : 'silo-input-base-diameter-display')
        "
        filled
        hide-details
        v-model="localProperty.baseDiameter"
        :disabled="display"
        @blur="calculateVolume"
      >
        <template v-slot:append
          ><v-container
            style="
              background: #264e6e;
              color: white;
              font-weight: bold;
              width: 100%;
              height: 100%;
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            "
            >mm</v-container
          ></template
        >
      </v-text-field>
      <v-text-field
        type="number"
        outlined
        dense
        class="silo-input silo-input-base-height"
        :class="
          'silo-input ' +
          (display == false
            ? 'silo-input-base-height'
            : 'silo-input-base-height-display')
        "
        filled
        hide-details
        v-model="localProperty.baseHeight"
        :disabled="display"
        @blur="calculateVolume"
      >
        <template v-slot:append
          ><v-container
            style="
              background: #264e6e;
              color: white;
              font-weight: bold;
              width: 100%;
              height: 100%;
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            "
            >mm</v-container
          ></template
        >
      </v-text-field>
      <v-text-field
        type="number"
        outlined
        dense
        :class="
          'silo-input ' +
          (display == false
            ? 'silo-input-bottom-diameter'
            : 'silo-input-bottom-diameter-display')
        "
        filled
        hide-details
        v-model="localProperty.bottomDiameter"
        :disabled="display"
        @blur="calculateVolume"
      >
        <template v-slot:append
          ><v-container
            style="
              background: #264e6e;
              color: white;
              font-weight: bold;
              width: 100%;
              height: 100%;
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            "
            >mm</v-container
          ></template
        >
      </v-text-field>
      <v-text-field
        type="number"
        outlined
        dense
        :class="
          'silo-input ' +
          (display == false
            ? 'silo-input-bottom-height'
            : 'silo-input-bottom-height-display')
        "
        filled
        hide-details
        v-model="localProperty.bottomHeight"
        :disabled="display"
        @blur="calculateVolume"
      >
        <template v-slot:append
          ><v-container
            style="
              background: #264e6e;
              color: white;
              font-weight: bold;
              width: 100%;
              height: 100%;
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            "
            >mm</v-container
          ></template
        >
      </v-text-field>
      <v-text-field
        type="number"
        outlined
        dense
        :class="
          'silo-input-outside ' +
          (display
            ? 'silo-input-install-height-display'
            : 'silo-input-install-height')
        "
        light
        hide-details
        v-model="localProperty.installHeight"
        :label="$t('silos.siloProperty.fields.installHeight')"
        :disabled="display"
        @blur="calculateVolume"
      >
      </v-text-field>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import siloRepository from "../../api/repositories/siloRepository";
export default {
  props: {
    modelValue: {
      type: Object,
      default: null,
    },

    display: {
      type: Boolean,
      default: false,
    },

    type: {
      type: Number,
      default: null,
    },

    displaySize: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      size: 0,
    };
  },

  model: {
    prop: "modelValue",
  },

  methods: {
    async calculateVolume() {
      if (this.displaySize && !Object.values(this.localProperty).includes(null))
        this.size = (
          await siloRepository.calculatePropertyVolume(
            this.currentUser,
            this.localProperty
          )
        ).data;
    },
  },

  computed: {
    ...mapState("users", ["currentUser"]),

    localProperty: {
      get() {
        return this.modelValue;
      },

      async set(val) {
        this.$emit("input", val);
      },
    },

    topDiameter: {
      get() {
        return this.localProperty == null ? 0 : this.localProperty.topDiameter;
      },

      set(val) {
        this.localProperty = {
          topDiameter: val,
          baseDiameter: this.baseDiameter,
          bottomDiameter: this.bottomDiameter,
          topHeight: this.topHeight,
          baseHeight: this.baseHeight,
          bottomHeight: this.bottomHeight,
          installHeight: this.installHeight,
        };
      },
    },

    baseDiameter: {
      get() {
        return this.localProperty == null ? 0 : this.localProperty.baseDiameter;
      },

      set(val) {
        this.localProperty = {
          topDiameter: this.topDiameter,
          baseDiameter: val,
          bottomDiameter: this.bottomDiameter,
          topHeight: this.topHeight,
          baseHeight: this.baseHeight,
          bottomHeight: this.bottomHeight,
          installHeight: this.installHeight,
        };
      },
    },

    bottomDiameter: {
      get() {
        return this.localProperty == null
          ? 0
          : this.localProperty.bottomDiameter;
      },

      set(val) {
        this.localProperty = {
          topDiameter: this.topDiameter,
          baseDiameter: this.baseDiameter,
          bottomDiameter: val,
          topHeight: this.topHeight,
          baseHeight: this.baseHeight,
          bottomHeight: this.bottomHeight,
          installHeight: this.installHeight,
        };
      },
    },

    topHeight: {
      get() {
        return this.localProperty == null ? 0 : this.localProperty.topHeight;
      },

      set(val) {
        this.localProperty = {
          topDiameter: this.topDiameter,
          baseDiameter: this.baseDiameter,
          bottomDiameter: this.bottomDiameter,
          topHeight: val,
          baseHeight: this.baseHeight,
          bottomHeight: this.bottomHeight,
          installHeight: this.installHeight,
        };
      },
    },

    baseHeight: {
      get() {
        return this.localProperty == null ? 0 : this.localProperty.baseHeight;
      },

      set(val) {
        this.localProperty = {
          topDiameter: this.topDiameter,
          baseDiameter: this.baseDiameter,
          bottomDiameter: this.bottomDiameter,
          topHeight: this.topHeight,
          baseHeight: val,
          bottomHeight: this.bottomHeight,
          installHeight: this.installHeight,
        };
      },
    },

    bottomHeight: {
      get() {
        return this.localProperty == null ? 0 : this.localProperty.bottomHeight;
      },

      set(val) {
        this.localProperty = {
          topDiameter: this.topDiameter,
          baseDiameter: this.baseDiameter,
          bottomDiameter: this.bottomDiameter,
          topHeight: this.topHeight,
          baseHeight: this.baseHeight,
          bottomHeight: val,
          installHeight: this.installHeight,
        };
      },
    },

    installHeight: {
      get() {
        return this.localProperty == null
          ? 0
          : this.localProperty.installHeight;
      },

      set(val) {
        this.localProperty = {
          topDiameter: this.topDiameter,
          baseDiameter: this.baseDiameter,
          bottomDiameter: this.bottomDiameter,
          topHeight: this.topHeight,
          baseHeight: this.baseHeight,
          bottomHeight: this.bottomHeight,
          installHeight: val,
        };
      },
    },
  },

  async created() {
    if (this.modelValue == null)
      this.localProperty = {
        topDiameter: 0,
        baseDiameter: 0,
        bottomDiameter: 0,
        topHeight: 0,
        baseHeight: 0,
        bottomHeight: 0,
        installHeight: 0,
      };
    else await this.calculateVolume();
  },
};
</script>

<style lang="scss">
.silo-container {
  position: relative;
  background-size: cover;

  .silo-input {
    position: absolute;
    max-width: 120px;
    min-width: 120px;
    max-height: 20px;

    .v-input__control {
      .v-input__slot {
        padding: 0 0 0 10px !important;
        background: rgb(232, 232, 232);
      }

      .v-input__append-inner {
        padding: 0 !important;
        margin: 0 !important;
      }
    }

    .v-input__control:hover {
      background: rgb(232, 232, 232);
      transition: none;
    }
  }

  .silo-input-top-diameter {
    top: 46px;
    left: 64px;
  }

  .silo-input-top-height {
    top: 39px;
    left: 230px;
  }

  .silo-input-base-diameter {
    top: 130px;
    left: 64px;
  }

  .silo-input-base-height {
    top: 130px;
    left: 230px;
  }
  .silo-input-bottom-diameter {
    top: 420px;
    left: 64px;
  }
  .silo-input-bottom-height {
    top: 300px;
    left: 230px;
  }

  // ================ DISPLAY ================= //
  .silo-input-top-diameter-display {
    top: 0px;
    left: 160px;
  }

  .silo-input-top-height-display {
    top: 42px;
    left: 160px;
  }

  .silo-input-base-diameter-display {
    top: 83px;
    left: 160px;
  }

  .silo-input-base-height-display {
    top: 124px;
    left: 160px;
  }
  .silo-input-bottom-diameter-display {
    top: 206px;
    left: 160px;
  }
  .silo-input-bottom-height-display {
    top: 165px;
    left: 160px;
  }

  // ================= INSTALL HEIGHT ================== //
  .silo-input-outside {
    color: black;
    background: white;
  }

  .silo-input-install-height {
    width: 140px;
    top: 470px;
    left: 55px;
  }

  .silo-input-install-height-display {
    width: 120px;
    top: 215px;
    left: 160px;
  }
}

.silo-display-small {
  background: url("/cyl-silo-display.png");
  background-size: cover;
}

.silo-create {
  background: url("/cyl-silo.png");
  background-size: cover;
  margin: 0 auto;
}
</style>