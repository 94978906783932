<template>
  <v-card>
    <v-card-title>{{ $t("silos.siloProperty.createTitle") }}</v-card-title>

    <v-card-text class="d-flex justify-space-between align-center">
      <v-text-field
        v-model="name"
        :label="$t('silos.siloProperty.fields.name')"
        prepend-inner-icon="mdi-video-input-hdmi"
      />

      <v-switch
        v-model="globalSilo"
        :label="$t('silos.siloProperty.fields.globalSilo')"
        :hint="$t('silos.siloProperty.hints.globalSilo')"
        persistent-hint
        class="ml-4"
      />
    </v-card-text>
    <v-card-text>
      <v-radio-group v-model="type">
        <v-radio :value="0">
          <template v-slot:label>
            <v-icon color="primary">mdi-cylinder</v-icon>
            {{ $t("silos.cylindrical") }}
          </template>
        </v-radio>
        <v-radio :value="1">
          <template v-slot:label>
            <v-icon color="primary">mdi-cube</v-icon>
            {{ $t("silos.cube") }}
          </template>
        </v-radio>
      </v-radio-group>
    </v-card-text>

    <v-card-text>
      <silo-property-item v-model="properties" display-size />
    </v-card-text>

    <error-list :errors="errors" />

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!hideDialogButton"
        color="grey darken-1"
        text
        @click="$emit('close-dialog')"
      >
        {{ $t("common.cancel") }}
      </v-btn>
      <v-btn color="blue darken-1" text @click="save()">
        {{ edit ? $t("common.save") : $t("common.add") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import SiloPropertyItem from "./SiloPropertyItem.vue";

export default {
  components: { SiloPropertyItem },

  props: {
    modelValue: {
      type: Object,
      default: null,
    },

    edit: {
      type: Boolean,
      default: false,
    },

    hideDialogButton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      name: "",
      globalSilo: false,
      type: 0,
      properties: null,
      errors: [],
    };
  },

  methods: {
    ...mapActions("silos", ["createProperty", "updateProperty"]),

    async save() {
      let err = [];
      if (this.name == "" || this.name == null) {
        err.push(this.$t("silos.siloProperty.errors.name"));
      }

      if (this.properties == null) {
        err.push(this.$t("silos.siloProperty.errors.properties"));
      }

      if (this.edit && this.modelValue.siloPropertyId == null) {
        err.push(this.$t("silos.siloProperty.errors.siloPropertyId"));
      }

      if (err.length > 0) {
        this.errors = err;
        return;
      }

      let payload = {
        name: this.name,
        globalSilo: this.globalSilo,
        type: this.type,
        topDiameter: this.properties.topDiameter,
        topHeight: this.properties.topHeight,
        bottomDiameter: this.properties.bottomDiameter,
        bottomHeight: this.properties.bottomHeight,
        baseDiameter: this.properties.baseDiameter,
        baseHeight: this.properties.baseHeight,
        installHeight: this.properties.installHeight,
      };

      if (!this.edit) await this.createProperty(payload);
      else
        await this.updateProperty({
          siloPropertyId: this.modelValue.siloPropertyId,
          payload: payload,
        });

      this.$emit("close-dialog");
    },
  },

  created() {
    if (this.modelValue != null) {
      this.name = this.modelValue.name;
      this.globalSilo = this.modelValue.globalSilo;
      this.type = this.modelValue.type;
      this.properties = {
        topDiameter: this.modelValue.topDiameter,
        topHeight: this.modelValue.topHeight,
        bottomDiameter: this.modelValue.bottomDiameter,
        bottomHeight: this.modelValue.bottomHeight,
        baseDiameter: this.modelValue.baseDiameter,
        baseHeight: this.modelValue.baseHeight,
        installHeight: this.modelValue.installHeight ?? 0,
      };
    }
  },
};
</script>